import { ChainName, ChainId, Chain } from '@particle-network/common';
import { WalletEntryPosition } from './components/walletEntry';

export class AuthError {
    constructor(public code: number, public message: string) {
        this.code = code;
        this.message = message;
    }

    public static userCancelOperation() {
        return new AuthError(4011, 'The user cancel the operation');
    }

    public static unauthorized() {
        return new AuthError(4100, 'The requested method and/or account has not been authorized by the user');
    }

    public static unsupportedMethod() {
        return new AuthError(4200, 'The Provider does not support the requested method');
    }

    public static unsupportedChain() {
        return new AuthError(4201, 'The Provider does not support the chain');
    }

    public static paramsError() {
        return new AuthError(8002, 'Param error, see doc for more info');
    }

    public static notLogin() {
        return new AuthError(8005, 'User not login');
    }

    public static walletNotCreated() {
        return new AuthError(8006, 'Wallet not created');
    }

    public static decrypt(message: string) {
        return new AuthError(8007, 'decrypt error: ' + message);
    }
}

export interface RequestArguments {
    method: string;
    params?: unknown;
}

export interface JsonRpcRequest extends RequestArguments {
    id: number | string;
    jsonrpc: string;
}

export interface JsonRpcError {
    code: number;
    message: string;
}

export interface JsonRpcResponse {
    id: number | string;
    jsonrpc: string;
    result?: any;
    error?: JsonRpcError;
}

export interface ParticleRpcRequest extends JsonRpcRequest {
    chainId: number;
}

export const AuthTypes = [
    'email',
    'phone',
    'facebook',
    'google',
    'apple',
    'discord',
    'github',
    'twitch',
    'twitter',
    'microsoft',
    'linkedin',
    'jwt',
] as const;

type AuthTypeTuple = typeof AuthTypes;

export type AuthType = AuthTypeTuple[number];

export interface SecurityAccount {
    email?: string;
    phone?: string;
    has_set_payment_password: boolean;
    has_set_master_password: boolean;
}

export interface UserInfo {
    uuid: string;
    token: string;
    wallets: Wallet[];
    name?: string;
    avatar?: string;
    phone?: string;
    email?: string;
    facebook_id?: string;
    facebook_email?: string;
    google_id?: string;
    google_email?: string;
    apple_id?: string;
    apple_email?: string;
    discord_id?: string;
    discord_email?: string;
    github_id?: string;
    github_email?: string;
    linkedin_id?: string;
    linkedin_email?: string;
    microsoft_id?: string;
    microsoft_email?: string;
    twitch_id?: string;
    twitch_email?: string;
    twitter_id?: string;
    twitter_email?: string;
    created_at?: string;
    updated_at?: string;
    thirdparty_user_info?: ThirdpartyUserInfo;
    jwt_id?: string;
    security_account?: SecurityAccount;
}

export type UserSimpleInfo = Pick<UserInfo, 'security_account'>;

export interface ThirdpartyUserInfo {
    provider: string;
    user_info: {
        id: string;
        name?: string;
        email?: string;
        picture?: string;
    };
}

export interface Wallet {
    uuid: string;
    chain_name: string;
    public_address: string;
}
export interface Config {
    projectId: string;
    clientKey: string;
    appId: string;
    chainName?: ChainName;
    chainId?: ChainId;
    securityAccount?: {
        promptSettingWhenSign?: SettingOption | boolean;
        promptMasterPasswordSettingWhenLogin?: SettingOption | boolean;
    };
    wallet?: WalletEntryOption;
}

export enum SettingOption {
    None = 0,
    Once = 1,
    Always = 2,
}

export interface ModeStyle {
    colorAccent?: string;
    colorPrimary?: string;
    colorOnPrimary?: string;
    primaryButtonBackgroundColors?: [string, string];
    primaryIconButtonBackgroundColors?: [string, string];
    primaryIconTextColor?: string;
    primaryButtonTextColor?: string;
    cancelButtonBackgroundColor?: string;
    backgroundColors?: [string, [[string, string], [string, string]]];
    messageColors?: string[];
    borderGlowColors?: string[];
    modalMaskBackgroundColor?: string;
}

export interface WalletCustomStyle {
    supportAddToken?: boolean;
    supportChains?: Chain[];
    displayTokenAddresses?: string[];
    displayNFTContractAddresses?: string[];
    priorityTokenAddresses?: string[];
    priorityNFTContractAddresses?: string[];
    fiatCoin?: 'USD' | 'CNY' | 'JPY' | 'HKD' | 'INR' | 'KRW';
    evmSupportWalletConnect?: boolean;
    supportUIModeSwitch?: boolean;
    supportLanguageSwitch?: boolean;
    light?: ModeStyle;
    dark?: ModeStyle;
}

export interface WalletEntryOption {
    displayWalletEntry?: boolean;
    preload?: boolean;
    defaultWalletEntryPosition?: WalletEntryPosition;
    supportChains?: Chain[];
    uiMode?: UIMode;
    customStyle?: WalletCustomStyle;
}

export type UIMode = 'dark' | 'light' | 'auto';

export interface AuthThemeConfig {
    uiMode?: UIMode;
    displayCloseButton?: boolean;
    displayWallet?: boolean;
    modalBorderRadius?: number;
}

type PromptType = 'none' | 'consent' | 'select_account';

export interface LoginOptions {
    preferredAuthType?: AuthType;
    account?: string;
    supportAuthTypes?: string;
    loginFormMode?: boolean;
    socialLoginPrompt?: PromptType;
    hideLoading?: boolean;
}

export type EVMProvider = any;
export type SolanaWallet = any;

export type OpenBuyNetwork =
    | 'Solana'
    | 'Ethereum'
    | 'Binance Smart Chain'
    | 'Arbitrum One'
    | 'Polygon'
    | 'Tron'
    | 'Optimism';

export interface OpenBuyOptions {
    network?: OpenBuyNetwork;
    fiatCoin?: string;
    cryptoCoin?: string;
    fiatAmt?: number;
    cryptAmt?: number;
    fixFiatCoin?: boolean;
    fixCryptoCoin?: boolean;
    fixFiatAmt?: boolean;
    walletAddress?: string;
}

export type OpenBuyOptionsKeys = keyof OpenBuyOptions;
