import { urlCrypto } from '@particle-network/crypto';
import { Auth } from '../auth';
import { controller } from '../controller';
import { AuthError, OpenBuyOptions, OpenBuyOptionsKeys, OpenBuyNetwork } from '../types';
import { isParticleDev } from './utils';

export interface WalletConfig {
    topMenuType?: 'close' | 'fullscreen';
}

export function walletUrl(auth: Auth, config?: WalletConfig): string {
    if (!auth.isLogin()) {
        throw AuthError.notLogin();
    }
    if (!auth.walletExist()) {
        throw AuthError.walletNotCreated();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { wallet, ...options } = auth.config;

    let supportChains = auth.config.wallet?.customStyle?.supportChains || auth.config.wallet?.supportChains;
    if (supportChains && auth.config.chainId && auth.config.chainName) {
        if (!supportChains.find((item) => item.id === auth.config.chainId && item.name === auth.config.chainName)) {
            supportChains.unshift({
                id: auth.config.chainId,
                name: auth.config.chainName,
            });
        }
    }

    supportChains = supportChains?.map((chain) => {
        const { id, name } = chain;
        return {
            id,
            name,
        };
    });

    const customStyle = auth.config.wallet?.customStyle;
    if (customStyle) {
        customStyle.supportChains = supportChains;
    }

    const params = {
        ...options,
        authUserInfo: auth.userInfo(),
        supportChains,
    };

    const ciphertext = urlCrypto.encryptUrlParam(params);
    let url;
    if (isParticleDev()) {
        url = `https://wallet-debug.particle.network/?params=${ciphertext}&theme=${getWalletUIMode(
            auth
        )}&language=${controller.getLanguage()}`;
    } else {
        url = `https://wallet.particle.network/?params=${ciphertext}&theme=${getWalletUIMode(
            auth
        )}&language=${controller.getLanguage()}`;
    }
    if (customStyle) {
        url += `&customStyle=${encodeURIComponent(Buffer.from(JSON.stringify(customStyle)).toString('base64'))}`;
    }

    const { uiMode, modalBorderRadius } = auth.getAuthTheme();
    url += `&authTheme=${encodeURIComponent(
        Buffer.from(
            JSON.stringify({
                uiMode,
                modalBorderRadius,
            })
        ).toString('base64')
    )}`;

    if (config && config.topMenuType) {
        url += `&topMenuType=${config.topMenuType}`;
    }
    return url;
}

export function buyUrl(auth: Auth, options?: OpenBuyOptions): string {
    let url: string;
    if (isParticleDev()) {
        url = 'https://ramp-debug.particle.network/';
    } else {
        url = 'https://ramp.particle.network/';
    }
    // if (isParticleDev()) {
    //     url = 'https://wallet-debug.particle.network/buy.html';
    // } else {
    //     url = 'https://wallet.particle.network/buy.html';
    // }

    url += `?language=${controller.getLanguage()}&theme=${getWalletUIMode(auth)}`;

    const customStyle = auth.config.wallet?.customStyle;
    if (customStyle) {
        url += `&customStyle=${encodeURIComponent(Buffer.from(JSON.stringify(customStyle)).toString('base64'))}`;
    }

    if (auth.isLogin() && auth.walletExist()) {
        if (options) {
            if (!options.walletAddress) {
                options.walletAddress = auth.wallet()?.public_address;
            }
            if (!options.network) {
                options.network = getOpenBuyNetwork(auth.config.chainName!);
            }
        } else {
            options = {
                walletAddress: auth.wallet()?.public_address,
                network: getOpenBuyNetwork(auth.config.chainName!),
            };
        }
    } else {
        if (!options || !options.network || !options.walletAddress) {
            throw new Error('network or walletAddress not found');
        }
    }

    if (options) {
        Object.keys(options).forEach((key) => {
            const value = options![key as OpenBuyOptionsKeys];
            if (value) {
                url += `&${key}=${encodeURIComponent(value)}`;
            }
        });
    }

    return url;
}

const getOpenBuyNetwork = (chainName: string): OpenBuyNetwork => {
    chainName = chainName.toLowerCase();
    let network: OpenBuyNetwork = 'Ethereum';
    if (chainName == 'bsc') {
        network = 'Binance Smart Chain';
    } else if (chainName == 'tron') {
        network = 'Tron';
    } else if (chainName == 'arbitrum') {
        network = 'Arbitrum One';
    } else if (chainName == 'optimism') {
        network = 'Optimism';
    } else if (chainName == 'polygon') {
        network = 'Polygon';
    } else if (chainName == 'solana') {
        network = 'Solana';
    } else {
        network = 'Ethereum';
    }
    return network;
};

export function getWalletUIMode(auth: Auth): string {
    let walletUIMode = auth.getThemeType();
    if (auth.config.wallet?.uiMode) {
        walletUIMode =
            auth.config.wallet?.uiMode === 'auto'
                ? window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                    ? 'dark'
                    : 'light'
                : auth.config.wallet?.uiMode;
    }
    return walletUIMode;
}
